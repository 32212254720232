var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.__show),expression:"__show"}],class:_vm.__class,style:({ width: _vm.width, height: _vm.height }),attrs:{"id":_vm._comId}},[_c('div',{staticClass:"uploader",attrs:{"id":`${_vm._comId}_uploader`}},[_c('div',{staticClass:"statusBar"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                    (_vm.fileCount > 0 || _vm.viewerFiles.length > 0) && !_vm.isDisabled
                ),expression:"\n                    (fileCount > 0 || viewerFiles.length > 0) && !isDisabled\n                "}],staticClass:"btns"},[_c('div',{staticClass:"filePicker2",attrs:{"id":`${_vm._comId}-filePicker2`}}),(_vm.showUploadBtn)?_c('div',{class:`uploadBtn state-${this.state}`,on:{"click":_vm.startUpload}},[_vm._v(" "+_vm._s(_vm.uploadBtnText)+" ")]):_vm._e()])]),_c('div',{staticClass:"statusBar"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.progress > 0),expression:"progress > 0"}],staticClass:"progress"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.progress)+"%")]),_c('span',{staticClass:"percentage",style:({ width: _vm.progress + '%' })})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.info),expression:"!!info"}],staticClass:"info"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.info)}})])]),_c('div',{staticClass:"queueList"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileCount == 0 && _vm.viewerFiles.length == 0),expression:"fileCount == 0 && viewerFiles.length == 0"}],staticClass:"placeholder",attrs:{"id":`${_vm._comId}_dndArea`}},[_c('p',[_vm._v(_vm._s(_vm.placeholder))])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileCount > 0),expression:"fileCount > 0"}],staticClass:"filelist"},_vm._l((_vm.getFiles),function(file,fIndex){return _c('li',{key:fIndex,class:`doc-bg ${file.ext} state-${file.state}`,attrs:{"id":'file-' + file.id}},[_c('p',{staticClass:"title",attrs:{"title":file.name}},[_vm._v(" "+_vm._s(file.short_name)+" ")]),_c('table',{staticClass:"imgWrap"},[_c('tr',{style:({ height: '100%' })},[_c('td',[_c('img',{attrs:{"title":file.name,"alt":file.name,"src":file.src ||
                                        _vm.errorFileIcon(file) + '.png',"data-file":file}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!!file.info),expression:"!!file.info"}]},[_vm._v(_vm._s(file.info))])])])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(file.progress != 0),expression:"file.progress != 0"}],staticClass:"progress",style:({ width: file.progress + '%' })},[_c('span')]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!!file.error),expression:"!!file.error"}],staticClass:"error"},[_vm._v(" "+_vm._s(file.error)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(file.complete),expression:"file.complete"}],staticClass:"success"})])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewerFiles.length > 0),expression:"viewerFiles.length > 0"}],staticClass:"queueList"},[_c('ul',{staticClass:"filelist viewer"},_vm._l((_vm.viewerFiles),function(file,fIndex){return _c('li',{key:fIndex,class:`doc-bg state-${file.state}`,attrs:{"id":'file-' + file.id},on:{"click":function($event){return _vm.openFile(file)}}},[_c('p',{staticClass:"title",attrs:{"title":file.name}},[_vm._v(" "+_vm._s(file.name.substring( 0, file.name.lastIndexOf('.') ))+" ")]),_c('table',{staticClass:"imgWrap"},[_c('tr',{style:({ height: '100%' })},[_c('td',[(!_vm.isDisabled)?_c('span',{staticStyle:{"position":"absolute","top":"2px","right":"2px"},on:{"click":function($event){$event.stopPropagation();return _vm.removeServerFile(file)}}},[_c('i',{staticClass:"fa fa-close"})]):_vm._e(),_c('img',{attrs:{"title":file.name,"alt":file.name,"src":_vm.finalServer +
                                        '/viewPic/' +
                                        file.id +
                                        '/' +
                                        _vm.thumb_size,"data-file":file,"onerror":'this.src=\'' +
                                        _vm.errorFileIcon(file) +
                                        '.png\';this.onerror=null'}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!!file.info),expression:"!!file.info"}]},[_vm._v(_vm._s(file.info))])])])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }