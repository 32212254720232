<template>
    <jgp-text :_id="_comId" :class="__class" v-show="__show" @onClick="onInputClick" @onChange="onInputChange" :_value="getValue"
              :_label="label"
              :_label_width="label_width"
              :_name="name"
              :_col="col"
              :_required="required"
              :_validator="`${getValid}`"
              :_placeholder="placeholder"
              :_hidden="hidden"
              :_readonly="getReadonly||notInput"
              :_disabled="getDisabled"
              :_padding="padding"
              :_tip="tip"
              :_submit="getSubmit"
              :_hastool="hastool"
              ref="text">

        <v-popover slot="tool" v-if="canDrop"
                   popoverClass="form-drop"
                   :auto-hide="true"
                   placement="auto"
                   auto-boundary-max-size
                   :open="showPanel">
            <a class="tool-label" href="javascript:void(0);" @click="showPanel=!showPanel">
                <i class="fa fa-calendar">&nbsp;</i>
            </a>
            <template slot="popover">
                <jgp-date-time-panel :_open="showPanel" @onUndo="closeDrop" @onSelected="onSelected" :_value="value" :_format="format"></jgp-date-time-panel>
            </template>
        </v-popover>

    </jgp-text>
</template>

<script>
/**
 * 时间日期组件
 * @module jgp-date-time
 * @desc 可用于显示 日期+时间 日期 月 时间
 * @example <jgp-date-time _label="日期" _name="date" _format="date"></jgp-date-time>
 * @example <jgp-date-time _label="时间" _name="time" _format="time"></jgp-date-time>
 * @example <jgp-date-time _label="月" _name="month" _format="month"></jgp-date-time>
 */
import Common from '../../utils/common'
import JgpDateTimePanel from './JgpDateTimePanel.vue'

const props = {
    '_format': {
        type: String,
        default: 'datetime'
    }, // date datetime time
    '_validator': String,
    '_value': String,
    '_placeholder': String,
    '_tip': String,
    '_label': String,
    '_name': String,
    '_col': {
        type: String | Number,
        default: '6'
    },
    '_required': String | Boolean,
    '_readonly': String,
    '_disabled': String,
    '_not_input': String,
    '_label_width': {
        type: String,
        default: '80px'
    },
    '_padding': {
        type: String,
        default: '5px 5px 0'
    },
    '_onchange': String,
    '_submit': {
        type: String | Boolean,
        default: 'true'
    }
}
export default {
    components: { JgpDateTimePanel },
    data() {
        return {
            styleClass: 'inline-control',
            showPanel: false,
            hastool: 'true'
        }
    },
    /**
         * @prop {String} _format 规定组件格式 datetime date month time ym
         * @prop {String} _value 默认值
         * @prop {String} _name
         * @prop {String} _label 标题
         * @prop {String} _label_width 标题宽度
         * @prop {String} _placeholder 提示信息
         * @prop {String} _tip 长文本提示 在LABEL hover的时候显示
         * @prop {String} _col 所占列宽
         * @prop {String} _required 是否可用 {默认:false}
         * @prop {String} _readonly 是否只读 {默认:false}
         * @prop {String} _disabled 是否禁用 {默认:false}
         * @prop {String} _not_input 是否可以手动输入 {默认:false} 默认是可以，设置为true 则只能选择，不能输入
         * @prop {String} _padding 内边距 {默认(只负责 上、左、右):'5px 5px 0 5px'}
         * @prop {String} _onchange onchange
         */
    props: props,
    computed: {
        getValid() {
            let valid = this.format;
            if (this.validator) {
                return valid + "|" + this.validator
            }
            return valid;
        },
        getValue() {
            // return this.value ? (this.value.indexOf('T') == -1 ? this.value.replace(' ', 'T') : this.value) : ''
            return this.value ? this.value : ''
        },
        hidden() {
            return {
                status: true,
                disValue: this.value ? this.value.replace('T', ' ') : undefined
            }
        },
        getReadonly() {
            return (this.form && this.form.getReadonly) || Common.toBool(this.readonly)
        },
        getDisabled() {
            return (this.form && this.form.getDisabled) || Common.toBool(this.disabled)
        },
        notInput() {
            return Common.toBool(this.not_input);
        },
        canDrop() {
            return !(Common.toBool(this.disabled) || Common.toBool(this.readonly) || this.form.getReadonly || this.form.getDisabled);
        },
        form() {
            return this.getForm(this);
        },
        formGroup() {
            return this.getFormGroup(this);
        },
        getSubmit() {
            return Common.toBool(this.submit);
        }
    },
    methods: {
        getForm(com) {
            if (this.parentLevel > 10) {
                return undefined
            }
            let parent = com.$parent;
            if (parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getForm(parent)
            }
        },
        getFormGroup(com) {
            let parent = com.$parent;
            if (this.parentLevel > 10 || parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return undefined
            }
            if (parent.cType === 'jgp-form-group') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getFormGroup(parent)
            }
        },
        onSelected(param) {
            this.value = Common.DATE.format(param.date, Common.DATE_FORMAT[this.format][0]);
            this.closeDrop();
        },
        onInputChange(param) {
            if (param.disValue) {
                this.value = param.disValue;// .replace(' ', 'T');
            } else if (!param.disValue && param.value) {
                this.value = param.value;
            } else if (!param.value) {
                this.value = undefined;
            }

            if (this.onchange) Common.doFn(this.onchange, this.getValue);
        },
        onInputClick(param) {
            this.showPanel = !this.showPanel
        },
        closeDrop() {
            this.showPanel = false;
        },
        /**
             * @name val
             * @function
             * @param value {Number}
             * @desc  获取值 JGP.datetime('id').val()。设置值 JGP.datetime('id').val(123)
             */
        val(value, submit) {
            if (Common.checkInputValue(value)) {
                this.$set(this, 'value', value);
            } else {
                return this.getValue;
            }
            if (typeof submit !== 'undefined') {
                this.$set(this, 'submit', submit);
            }
        },
        /**
             * @name reset
             * @function
             * @desc 清空已输入的内容
             */
        reset() {
            this.value = undefined;
        },
        valid() {
            return this.$refs.text.valid(); ;
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
        if (this.form) {
            this.form.removeEl(this.name);
        }
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
