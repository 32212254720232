<template>
    <div
        v-if="!needRenderForm"
        :id="_comId"
        :class="`${__class} layout-${cols} label-align-${label_align}`"
        :style="{ width: width, height: height }"
        v-show="__show"
    >
        <form :action="action" :method="method" :name="_comId" ref="form">
            <slot></slot>
            <jgp-hidden
                :key="index"
                v-for="(h, index) in hiddens"
                :_name="h.name"
                :_value="h.value"
            ></jgp-hidden>
            <input type="hidden" name="pager.current" :value="getPage" />
            <input type="hidden" name="pager.pageSize" :value="getPageSize" />
        </form>
    </div>
    <div
        v-else
        :id="_comId"
        :class="`${__class} layout-${formInfo.cols} label-align-${label_align}`"
        :style="{ width: width, height: height }"
        v-show="__show"
    >
        <form :action="action" :method="method" :name="_comId" ref="form">
            <slot></slot>
            <component
                v-for="attr in formAttrs"
                :is="getInputType(attr.type)"
                v-if="attr.forSearch"
                :key="attr.id"
                :_id="attr.id"
                :_label_width="attr.labelWidth"
                :_url="attr.dropType == 'REST' ? attr.dataSource : ''"
                :_col="attr.queryCol"
                :_mode="attr.type == 'DROP_TREE' ? 'tree' : undefined"
                :_tree_show_root="false"
                :_format="getFormat(attr.type)"
                :_multi="getFormat(attr.type)"
                :_active_key="
                    attr.dropType == 'ACTIVE_KEY' ? attr.dataSource : undefined
                "
                :_name="attr.name"
                :_label="attr.label"
                :_query_operator="attr.queryOperator"
                :_value="attr.queryValue"
            >
            </component>
            <input type="hidden" name="pager.current" :value="getPage" />
            <input type="hidden" name="pager.pageSize" :value="getPageSize" />
        </form>
    </div>
</template>

<script>
/**
 * 查询组件
 * @module jgp-query
 * @desc 其实就是表单组件的变种，用于传递查询条件
 */
import _ from 'lodash'
import Common from '../../utils/common'
import Options, { OPT } from '../../utils/options'
/*
 * 项目   vue
 * 作者   loufei
 * 时间   2018/2/22
 */
export default {
    validator: null,
    data() {
        return {
            formInfo: {},
            formAttrs: [],
            query: null,
            hiddens: [],
            els: [],
            keyValue: {},
            sorts: {}
        }
    },
    /**
     * @prop {String} _auto 是否自动搜索，搜索框里的内容变化后会自动搜索
     * @prop {String} _action 数据源地址
     * @prop {String} _target 目标表格
     * @prop {String} _method 提交方式:POST,GET (html 中 form method)
     * @prop {String} _cols 布局 可选24列或12列 默认:12(既12列布局)
     * @prop {String} _label_width 标签宽度 {单位:px,默认:80px}
     * @prop {String} _width 标签宽度 {单位:px}
     * @prop {String} _height 标签高度 {单位:px}
     * @prop {String} _page 用于向后台传输分页信息 当前页码
     * @prop {String} _page_size 用于向后台传输分页信息 每页记录个数
     * @prop {String} _readonly 表单输入项为只读，不可通过键盘输入，选择类型的输入项可通过选择设置输入项内容，比如checkbox，radio，drop，日期等
     * @prop {String} _disabled 表单不可编辑，不可输入，不可选择，不可搜索
     */
    props: {
        _auto: {
            type: String,
            default: 'true'
        },
        _action: String,
        _server: String,
        _method: {
            type: String,
            default: 'post'
        },
        _cols: {
            type: String,
            default: '12'
        },
        _label_width: {
            type: String
        },
        _label_align: {
            type: String,
            default: 'left'
        },
        _target: String,
        _callback: String,
        _page: String,
        _page_size: String,
        _readonly: {
            type: String,
            default: 'false'
        },
        _disabled: {
            type: String,
            default: 'false'
        },
        _json: {
            type: String,
            default: 'false'
        },
        _render_form_code: String,
        _render_form_id: String,
        _render_form_data_alias: {
            type: String,
            default: 'formInfo'
        },
        _render_form_url: String
    },
    computed: {
        isAuto() {
            return Common.toBool(this.auto)
        },
        isJson() {
            return Common.toBool(this.json)
        },
        targetCom() {
            if (this.target) {
                let com = Options.com(OPT.GRID.type, this.target)
                com.query = this
                return com
            }
        },
        hasError() {
            let errors = this.validData.errors
            let flag = false
            for (let key in errors) {
                if (errors[key]['items'].length > 0) {
                    flag = true
                }
            }
            return flag
        },
        getPage() {
            return this.page ? parseInt(this.page) : 1
        },
        getPageSize() {
            return this.page_size ? parseInt(this.page_size) : 15
        },
        getReadonly() {
            return Common.toBool(this.readonly)
        },
        getDisabled() {
            return Common.toBool(this.disabled)
        },
        needRenderForm() {
            return !!this.render_form_id || !!this.render_form_code
        },
        formAttrMap() {
            var map = {}
            for (let attr of this.formAttrs) {
                map[attr.name] = attr
            }
            return map
        }
    },
    methods: {
        renderForm() {
            const _this = this
            _this.action =
                _this.action || '/form/formApiController/queryFormValue'
            _this.render_form_url =
                _this.render_form_url || '/form/formApiController/formInfo/'
            Common.post(
                _this.server + _this.render_form_url,
                {
                    formId: _this.render_form_id,
                    formCode: _this.render_form_code
                },
                function (result) {
                    _this.loadConfigSource()
                    const formInfo = result.data[_this.render_form_data_alias]
                    _this.formInfo = formInfo.form
                    _this.formAttrs = formInfo.attrs
                    _this.id = _this.render_form_id
                }
            )
        },
        getInputType(type) {
            let comName = ''
            switch (type) {
                case 'TEXT':
                    comName = 'jgp-text'
                    break
                case 'AREA':
                    comName = 'jgp-area'
                    break
                case 'DATE_TIME':
                case 'DATE':
                case 'TIME':
                case 'YEAR':
                case 'MONTH':
                    comName = 'jgp-date-time'
                    break
                case 'NUMBER':
                    comName = 'jgp-num'
                    break
                case 'DROP_TREE':
                    comName = 'jgp-drop'
                    break
                case 'LIST_SINGLE':
                    comName = 'jgp-radio'
                    break
                case 'LIST_MULTI':
                    comName = 'jgp-checkbox'
                    break
                case 'DROP_SINGLE':
                    comName = 'jgp-drop'
                    break
                case 'DROP_MULTI':
                    comName = 'jgp-drop'
                    break
            }
            return comName
        },
        getFormat(type) {
            let format
            switch (type) {
                case 'DATE_TIME':
                    format = 'datetime'
                    break
                case 'DATE':
                    format = 'date'
                    break
                case 'TIME':
                    format = 'time'
                    break
                case 'YEAR':
                    format = 'year'
                    break
                case 'MONTH':
                    format = 'month'
                    break
                case 'DROP_SINGLE':
                    format = false
                    break
                case 'DROP_MULTI':
                    format = true
                    break
            }
            return format
        },
        enterSearch() {
            if (!this.isAuto) {
                this.search()
            }
        },
        loadQueryFilters() {
            if (this.query && this.els.length > 0) {
                for (let $el of this.els) {
                    $el.el.value = this.query[$el.name]
                }
            }
        },
        /**
         * 搜索
         * @param {function} callback - 成功回调.
         * @param {function} errorCallback - 失败回调.
         */
        search: _.debounce(function (
            callback,
            errorCallback,
            params = {},
            resetPage = true
        ) {
            if (this.getDisabled) {
                Common.warn('已禁用无法搜索')
                return
            }
            if (this.action) {
                const _this = this
                this.$nextTick(() => {
                    if (!errorCallback) errorCallback = callback
                    let query = Common.getFormData(_this.$refs.form)

                    if (resetPage) {
                        this.$set(this, 'page', 1)
                    }

                    if (_this.needRenderForm || _this.isJson) {
                        if (_this.needRenderForm) {
                            Object.assign(query, {
                                formId: _this.render_form_id,
                                formCode: _this.render_form_code
                            })
                            let filters = []
                            for (let key in query) {
                                if (
                                    key !== 'pager.current' &&
                                    key !== 'pager.pageSize' &&
                                    key !== 'formCode' &&
                                    key !== 'orderAttr' &&
                                    key !== 'orderDirection'
                                ) {
                                    if (query[key]) {
                                        filters.push({
                                            name: key,
                                            operator: _this.formAttrMap[key]
                                                ? _this.formAttrMap[key]
                                                      .queryOperator
                                                : undefined,
                                            value: query[key]
                                        })
                                    }
                                    delete query[key]
                                }
                            }
                            query['filters'] = filters
                        } else if (_this.isJson) {
                            for (let key of Object.keys(query)) {
                                if (
                                    key !== 'pager.current' &&
                                    key !== 'pager.pageSize' &&
                                    key !== 'formCode' &&
                                    key !== 'orderAttr' &&
                                    key !== 'orderDirection'
                                ) {
                                    if (key.indexOf('.') > -1) {
                                        const keys = key.split('.')
                                        if (!query[keys[0]]) {
                                            query[keys[0]] = {}
                                        }

                                        if (_.isArray(this.keyValue[key])) {
                                            query[keys[0]][keys[1]] =
                                                query[key].join(',')
                                        } else {
                                            query[keys[0]][keys[1]] = query[key]
                                        }

                                        query[keys[0]][keys[1]] = query[key]
                                        delete query[key]
                                    } else {
                                        if (_.isArray(this.keyValue[key])) {
                                            query[key] = this.keyValue[key]
                                        }
                                    }
                                }
                            }

                            Object.assign(query, params)
                        }

                        query['pager'] = {
                            current: _this.getPage,
                            pageSize: _this.getPageSize
                        }
                        delete query['pager.current']
                        delete query['pager.pageSize']

                        Common.postJson(
                            _this.server + _this.action,
                            query,
                            (result) => {
                                if (_this.targetCom) {
                                    _this.targetCom.loadFromQuery(result.data)
                                }
                                if (callback) callback(result)
                                if (_this.callback) {
                                    Common.doFn(callback, result)
                                }
                            },
                            errorCallback
                        )
                    } else {
                        const form = _this.$refs.form
                        let data = Common.getFormData(form)
                        data = Object.assign(data, this.sorts)
                        let config = {}
                        Common.http(
                            form.method,
                            form.getAttribute('action') || form.action,
                            data,
                            (result) => {
                                if (_this.targetCom) {
                                    _this.targetCom.loadFromQuery(result.data)
                                }
                                if (callback) callback(result)
                                if (_this.callback) {
                                    Common.doFn(callback, result)
                                }
                            },
                            errorCallback,
                            config
                        )
                    }
                })
            }
        },
        500),
        /**
         * @name searchTo
         * @function
         * @param action {String} 提交地址
         * @param callback {Function} 成功回调
         * @param errorCallback {Function} 失败回调 如果errorCallback为空则失败回调也会掉callback
         * @desc 搜索
         */
        searchTo(action, callback, errorCallback, page) {
            if (this.getDisabled) {
                Common.warn('已禁用无法搜索')
                return
            }
            this.$set(this, 'page', page === undefined ? 1 : page)
            this.$set(this, 'action', action)
            this.$nextTick(() => {
                this.search(callback, errorCallback)
            })
        },
        searchToWithJson(action, params, callback, errorCallback, page) {
            if (this.getDisabled) {
                Common.warn('已禁用无法搜索')
                return
            }
            this.$set(this, 'page', page === undefined ? 1 : page)
            this.$set(this, 'action', action)
            this.$nextTick(() => {
                this.search(callback, errorCallback, params)
            })
        },
        // 私有方法 不对外公开
        setKeyValue(name, value) {
            this.$set(this.keyValue, name, value)
        },
        /**
         * @name field
         * @function
         * @param name {Function} 表单项name
         * @param value {Function} 表单项设置
         * @desc 用于设置表单项的值，如果表单中没有此name，则新建hidden
         */
        field(name, value) {
            let flag = false
            let val
            for (let $el of this.els) {
                if ($el.el.name === name) {
                    if (!Common.checkInputValue(value)) {
                        val = $el.el.val()
                    } else {
                        $el.el.val(value)
                    }
                    flag = true
                }
            }
            if (!flag) {
                for (let h of this.hiddens) {
                    if (h.name === name) {
                        if (!Common.checkInputValue(value)) {
                            val = h.value
                        } else {
                            h.value = value
                        }
                        flag = true
                    }
                }
            }

            if (!flag && Common.checkInputValue(value)) {
                this.hiddens.push({ name: name, value: value })
            } else {
                return val
            }
        },
        /**
         * @name page
         * @function
         * @param &#112;&#97;&#103;&#101;&#114;&#58;&#123;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#99;&#117;&#114;&#114;&#101;&#110;&#116;&#58;&#50;&#44;&#47;&#47;&#36339;&#36716;&#30340;&#39029;&#30721;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#112;&#97;&#103;&#101;&#83;&#105;&#122;&#101;&#58;&#49;&#48;&#47;&#47;&#27599;&#39029;&#35760;&#24405;&#25968;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#125;
         * @desc 转到指定页数
         */
        goPage(pager) {
            this.$set(this, 'page', pager.current)
            this.$set(this, 'page_size', pager.pageSize)
            this.$nextTick(() => {
                this.search(
                    () => {},
                    () => {},
                    {},
                    false
                )
            })
        },
        /**
         * @name reset
         * @function
         * @desc 清空已输入的内容
         */
        reset() {
            for (let $el of this.els) {
                $el.el.reset()
            }
        },
        loadConfigSource() {
            const drops = ['jgp-drop', 'jgp-checkbox', 'jgp-radio']
            let inputs = this.els
                .filter((el) => {
                    return drops.indexOf(el.el.cType) !== -1
                })
                .map((el) => el.el)
            let postData = {}

            for (let i = 0; i < inputs.length; i++) {
                let input = inputs[i]
                let active_key = inputs[i].active_key
                if (active_key) {
                    postData[active_key] = input
                }
            }
            let activeKeys = Object.keys(postData)
            if (activeKeys.length > 0) {
                Common.postJson(
                    '/sys/config-drop-all',
                    activeKeys,
                    (result) => {
                        if (result.flag) {
                            let dropMap = result.data.dropMap
                            for (let key in postData) {
                                let el = postData[key]
                                let list = dropMap[key]
                                el.attr('_list', list)
                            }
                        }
                    }
                )
            }
        },
        setSorts(sorts) {
            this.sorts = sorts
        }
    },
    watch: {
        render_form_code() {
            this.$nextTick(() => {
                this.renderForm()
            })
        },
        render_form_id() {
            this.$nextTick(() => {
                this.renderForm()
            })
        },
        keyValue: {
            handler(curVal, oldVal) {
                this.$set(this, 'page', 1)
                if (this.isAuto) {
                    this.$nextTick(() => {
                        this.search()
                    })
                }
            },
            deep: true
        },
        hiddens: {
            handler(curVal, oldVal) {
                this.$set(this, 'page', 1)
                if (this.isAuto) {
                    this.search()
                }
            },
            deep: true
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {},
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
        this.loadQueryFilters()
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {},
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        if (this.render_form_id || this.render_form_code) {
            this.renderForm()
        } else {
            this.loadConfigSource()
        }
        if (this.isAuto) this.search()
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {},
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {},
    /* keep-alive 组件激活时调用。 */
    activated() {},
    /* keep-alive 组件停用时调用。 */
    deactivated() {},
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {},
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {}
}
</script>
