<template>
    <div
        :id="_comId"
        :class="`${__class} ${styleClass} col-${col} ${
            getReadonly ? 'readonly' : ''
        } ${getDisabled ? 'disabled' : ''}`"
        v-show="__show"
        :style="{ padding: padding }"
    >
        <div
            :class="`label-wrap`"
            v-if="!!label"
            :style="`width: ${getLabelwidth}`"
            v-tooltip="{ classes: ['info'], content: tip, trigger: 'hover' }"
        >
            <label :for="`input-${_comId}`"
                ><span>{{ label }}</span
                ><b v-if="getRequired">*</b></label
            >
        </div>

        <div
            :class="`input-wrap ${focus} ${
                errors.has(name) || errors.has(`${name}_hasHidden`)
                    ? 'is-danger'
                    : ''
            }`"
            :style="{
                width: `calc(100% - ${!!label ? getLabelwidth : '0px'})`
            }"
        >
            <div
                v-if="getHasIcon || icon"
                class="icon-wrap"
                style="width: 22px"
            >
                <slot name="icon" v-if="!icon"></slot>
                <a v-if="icon" class="icon-label" href="javascript:void(0);">
                    <span class="icon"><i :class="`fa fa-${icon}`"></i></span>
                </a>
            </div>
            <input
                autocomplete="off"
                :id="`input-${_comId}`"
                type="text"
                v-model="value"
                v-if="!hidden.status"
                :style="{ 'padding-left': inputLeft }"
                @click="onClick"
                :name="name"
                v-tooltip="{
                    classes: ['is-danger'],
                    content: errors.first(name),
                    trigger: 'hover'
                }"
                v-validate="getValid"
                :data-vv-as="label"
                v-on:focus="focus = 'focus'"
                v-on:blur="focus = ''"
                :readonly="getReadonly"
                :disabled="getDisabled"
                :data-submit="getSubmit"
                onkeydown="if(event.keyCode==13) return false;"
                :placeholder="placeholder"
            />

            <input
                autocomplete="off"
                :id="`input-${_comId}`"
                type="text"
                :name="`${name}_hasHidden`"
                v-model="hidden.disValue"
                v-if="hidden.status"
                v-on:focus="focus = 'focus'"
                v-on:blur="focus = ''"
                :style="{ 'padding-left': inputLeft }"
                v-tooltip="{
                    classes: ['is-danger'],
                    content: errors.first(`${name}_hasHidden`),
                    trigger: 'hover'
                }"
                v-validate="getValid"
                @click="onClick"
                :data-vv-as="label"
                :readonly="getReadonly"
                :disabled="getDisabled"
                :data-submit="getSubmit"
                onkeydown="if(event.keyCode==13) return false;"
                :placeholder="placeholder"
            />
            <input
                :disabled="getDisabled"
                type="hidden"
                v-model="value"
                v-if="hidden.status"
                :data-submit="getSubmit"
                :name="name"
            />

            <div v-if="getHasTool && !getDisabled" class="tool-wrap">
                <slot name="tool"></slot>
            </div>
        </div>
        <slot name="hidden"></slot>
    </div>
</template>
<script>
/**
 * 文本框组件
 * @module jgp-text
 * @desc 类似于 HTML label + input
 */
import Common from '../../utils/common'
// TODO xxxxx替换成store所属模块
const event = {
    TOOL_ACTION: 'onToolAction',
    CHANGE: 'onChange',
    FOCUS: 'onFocus',
    BLUR: 'onBlur',
    CLICK: 'onClick'
}
/*
 * 项目   vue
 * 作者   loufei
 * 时间   2018/2/22
 */
export default {
    data() {
        return {
            styleClass: 'inline-control',
            focus: ''
        }
    },
    /**
     * @prop {String} _value 初始化值
     * @prop {String} _icon 图标 fa fa-{xxxxx} 只用括号中的内容 {@link http://fontawesome.dashgame.com/ }
     * @prop {String} _label 标题
     * @prop {String} _label_width 标题宽度
     * @prop {String} _name
     * @prop {String} _placeholder 提示内容
     * @prop {String} _tip 长文本提示 在LABEL hover的时候显示
     * @prop {String} _col 所占列宽
     * @prop {String} _required 是否可用 {默认:false}
     * @prop {String} _readonly 是否只读 {默认:false}
     * @prop {String} _disabled 是否禁用 {默认:false}
     * @prop {String} _padding 内边距 {默认(只负责 上、左、右):'5px 5px 0 5px'}
     * @prop {String} _onchange change事件
     * @prop {String} _onclick click事件
     */
    props: {
        _value: null,
        _icon: String,
        _label: String,
        _label_width: {
            type: String,
            default: '80px'
        },
        _name: String,
        _col: {
            type: String | Number,
            default: '6'
        },
        _validator: {
            type: String
        },
        _required: {
            type: String | Boolean,
            default: 'false'
        },
        _readonly: {
            type: String | Boolean,
            default: 'false'
        },
        _disabled: {
            type: String | Boolean,
            default: 'false'
        },
        _placeholder: String,
        _tip: String,
        _hastool: {
            type: String,
            default: 'false'
        },
        _hasicon: {
            type: String,
            default: 'false'
        },
        _hidden: {
            type: Object,
            default() {
                return {
                    status: false,
                    disValue: undefined
                }
            }
        },
        _onchange: String,
        _onfocus: String,
        _onblur: String,
        _onclick: String,
        _padding: {
            type: String,
            default: '5px 5px 0'
        },
        _submit: {
            type: String | Boolean,
            default: 'true'
        }
    },
    computed: {
        getLabelwidth() {
            let formGroup = this.formGroup
            let form = this.form
            let parentLabelWidth =
                formGroup && formGroup.label_width
                    ? formGroup.label_width
                    : form && form.label_width
                    ? form.label_width
                    : undefined
            return parentLabelWidth || this.label_width
        },
        inputLeft() {
            return !!this.getHasIcon || !!this.icon ? '20px' : null
        },
        getValid() {
            let str = 'max:200|'
            if (this.getRequired && this.validator) {
                str = 'required|' + this.validator
            } else if (!this.getRequired && this.validator) {
                str = this.validator
            } else if (this.getRequired) {
                str = 'required'
            }
            if (this.getDisabled) {
                str = ''
            }
            return str
        },
        getRequired() {
            return Common.toBool(this.required)
        },
        getReadonly() {
            return (
                (this.form && this.form.getReadonly) ||
                Common.toBool(this.readonly)
            )
        },
        getDisabled() {
            return (
                (this.form && this.form.getDisabled) ||
                Common.toBool(this.disabled)
            )
        },
        getHasTool() {
            return Common.toBool(this.hastool)
        },
        getHasIcon() {
            return Common.toBool(this.hasicon)
        },
        form() {
            return this.getForm(this)
        },
        formGroup() {
            return this.getFormGroup(this)
        },
        getSubmit() {
            return Common.toBool(this.submit) ? 'yes' : 'no'
        }
    },

    watch: {
        value(value) {
            this.$emit(event.CHANGE, {
                inputType: 'text',
                value: value,
                disValue: this.disValue
            })
            if (!!this.form && this.form.changeErrors) {
                this.form.changeErrors(this.name, this.errors)
            }
            if (!!this.form && this.form.setKeyValue) {
                this.form.setKeyValue(this.name, value)
            }
            if (this.onchange) Common.doFn(this.onchange, value)
            this.$emit('change', value)
        },
        'hidden.disValue'(value) {
            this.$emit(event.CHANGE, {
                inputType: 'hidden',
                value: this.value,
                disValue: value
            })
        }
    },
    methods: {
        getForm(com) {
            if (this.parentLevel > 10) {
                return undefined
            }
            let parent = com.$parent
            if (!parent) return undefined
            if (parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return parent
            } else {
                this.parentLevel += 1
                return this.getForm(parent)
            }
        },
        getFormGroup(com) {
            let parent = com.$parent
            if (!parent) return undefined
            if (
                this.parentLevel > 10 ||
                parent.cType === 'jgp-form' ||
                parent.cType === 'jgp-query'
            ) {
                return undefined
            }
            if (parent.cType === 'jgp-form-group') {
                return parent
            } else {
                this.parentLevel += 1
                return this.getFormGroup(parent)
            }
        },
        onClick() {
            if (this.onclick) {
                Common.doFn(this.onclick, {
                    value: this.value,
                    disValue: this.hidden.disValue
                })
            }
            this.$emit(event.CLICK, {
                value: this.value,
                disValue: this.hidden.disValue
            })
        },
        /**
         * @name val
         * @function
         * @param value {String}
         * @desc  获取值 JGP.text('jgp-text-id').val()。设置值 JGP.text('jgp-text-id').val('123')
         */
        val(value, submit) {
            if (!Common.checkInputValue(value)) {
                return this.value ? this.value : undefined
            } else {
                if (value === null || value === undefined) {
                    this.value = undefined
                } else {
                    this.$set(this, 'value', value)
                }
            }
            if (typeof submit !== 'undefined') {
                this.$set(this, 'submit', submit)
            }
        },
        /**
         * @name reset
         * @function
         * @desc 清空已输入的内容
         */
        reset() {
            this.value = undefined
        },
        valid() {
            return this.$validator.validate()
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {},
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
        if (!!this.form && this.$parent.cType !== 'jgp-drop') {
            this.form.els.push({ name: this.name, el: this })
        }
        this.$watch('focus', (newVal, oldVal) => {
            if (newVal === 'focus') {
                this.$emit(event.FOCUS, {
                    newVal: newVal,
                    value: this.value,
                    disValue: this.hidden.disValue
                })
                if (this.onfocus) {
                    Common.doFn(this.onfocus, { obj: this, value: this.value })
                }
                this.$emit('focus', this.value)
            } else if (newVal === '') {
                this.$emit(event.BLUR, {
                    newVal: newVal,
                    value: this.value,
                    disValue: this.hidden.disValue
                })
                if (this.onblur) {
                    Common.doFn(this.onblur, { obj: this, value: this.value })
                }
                this.$emit('blur', this.value)
            }
        })
        if (!!this.form && this.form.changeErrors) {
            this.form.changeErrors(this.name, this.errors)
        }
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
        /* if (this.validControl)
             this.validControl.attach({name: this.name,rules:this.getValid}); */
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {},
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {},
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {},
    /* keep-alive 组件激活时调用。 */
    activated() {},
    /* keep-alive 组件停用时调用。 */
    deactivated() {},
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
        if (this.form) {
            this.form.removeEl(this.name)
        }
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {}
}
</script>
