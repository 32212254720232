/**
 * 项目   skin
 * 作者   loufei
 * 时间   2018/4/25
 */
export const MIME_TYPE = {
    ofd: 'application/ofd',
    ez: 'application/andrew-inset',
    tsp: 'application/dsptype',
    spl: 'application/futuresplash',
    hta: 'application/hta',
    hqx: 'application/mac-binhex40',
    cpt: 'application/mac-compactpro',
    nb: 'application/mathematica',
    mdb: 'application/msaccess',
    oda: 'application/oda',
    ogg: 'application/ogg',
    pdf: 'application/pdf',
    key: 'application/pgp-keys',
    pgp: 'application/pgp-signature',
    prf: 'application/pics-rules',
    rar: 'application/rar',
    rdf: 'application/rdf+xml',
    rss: 'application/rss+xml',
    zip: 'application/zip',
    apk: 'application/vnd.android.package-archive',
    cdy: 'application/vnd.cinderella',
    stl: 'application/vnd.ms-pki.stl',
    odb: 'application/vnd.oasis.opendocument.database',
    odf: 'application/vnd.oasis.opendocument.formula',
    odg: 'application/vnd.oasis.opendocument.graphics',
    otg: 'application/vnd.oasis.opendocument.graphics-template',
    odi: 'application/vnd.oasis.opendocument.image',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    ots: 'application/vnd.oasis.opendocument.spreadsheet-template',
    odt: 'application/vnd.oasis.opendocument.text',
    odm: 'application/vnd.oasis.opendocument.text-master',
    ott: 'application/vnd.oasis.opendocument.text-template',
    oth: 'application/vnd.oasis.opendocument.text-web',
    kml: 'application/vnd.google-earth.kml+xml',
    kmz: 'application/vnd.google-earth.kmz',
    doc: 'application/msword',
    dot: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    xls: 'application/vnd.ms-excel',
    xlt: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    ppt: 'application/vnd.ms-powerpoint',
    pot: 'application/vnd.ms-powerpoint',
    pps: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
    ppsx: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    cod: 'application/vnd.rim.cod',
    mmf: 'application/vnd.smaf',
    sdc: 'application/vnd.stardivision.calc',
    sda: 'application/vnd.stardivision.draw',
    sdd: 'application/vnd.stardivision.impress',
    sdp: 'application/vnd.stardivision.impress',
    smf: 'application/vnd.stardivision.math',
    sdw: 'application/vnd.stardivision.writer',
    vor: 'application/vnd.stardivision.writer',
    sgl: 'application/vnd.stardivision.writer-global',
    sxc: 'application/vnd.sun.xml.calc',
    stc: 'application/vnd.sun.xml.calc.template',
    sxd: 'application/vnd.sun.xml.draw',
    std: 'application/vnd.sun.xml.draw.template',
    sxi: 'application/vnd.sun.xml.impress',
    sti: 'application/vnd.sun.xml.impress.template',
    sxm: 'application/vnd.sun.xml.math',
    sxw: 'application/vnd.sun.xml.writer',
    sxg: 'application/vnd.sun.xml.writer.global',
    stw: 'application/vnd.sun.xml.writer.template',
    vsd: 'application/vnd.visio',
    abw: 'application/x-abiword',
    dmg: 'application/x-apple-diskimage',
    bcpio: 'application/x-bcpio',
    torrent: 'application/x-bittorrent',
    cdf: 'application/x-cdf',
    vcd: 'application/x-cdlink',
    pgn: 'application/x-chess-pgn',
    cpio: 'application/x-cpio',
    deb: 'application/x-debian-package',
    udeb: 'application/x-debian-package',
    dcr: 'application/x-director',
    dir: 'application/x-director',
    dxr: 'application/x-director',
    dms: 'application/x-dms',
    wad: 'application/x-doom',
    dvi: 'application/x-dvi',
    flac: 'application/x-flac',
    pfa: 'application/x-font',
    pfb: 'application/x-font',
    gsf: 'application/x-font',
    pcf: 'application/x-font',
    'pcf.z': 'application/x-font',
    mm: 'application/x-freemind',
    gnumeric: 'application/x-gnumeric',
    sgf: 'application/x-go-sgf',
    gcf: 'application/x-graphing-calculator',
    gtar: 'application/x-gtar',
    tgz: 'application/x-gtar',
    taz: 'application/x-gtar',
    hdf: 'application/x-hdf',
    ica: 'application/x-ica',
    ins: 'application/x-internet-signup',
    isp: 'application/x-internet-signup',
    iii: 'application/x-iphone',
    iso: 'application/x-iso9660-image',
    jmz: 'application/x-jmol',
    chrt: 'application/x-kchart',
    kil: 'application/x-killustrator',
    skp: 'application/x-koan',
    skd: 'application/x-koan',
    skt: 'application/x-koan',
    skm: 'application/x-koan',
    kpr: 'application/x-kpresenter',
    kpt: 'application/x-kpresenter',
    ksp: 'application/x-kspread',
    kwd: 'application/x-kword',
    kwt: 'application/x-kword',
    latex: 'application/x-latex',
    lha: 'application/x-lha',
    lzh: 'application/x-lzh',
    lzx: 'application/x-lzx',
    frm: 'application/x-maker',
    maker: 'application/x-maker',
    frame: 'application/x-maker',
    fb: 'application/x-maker',
    book: 'application/x-maker',
    fbdoc: 'application/x-maker',
    mif: 'application/x-mif',
    wmd: 'application/x-ms-wmd',
    wmz: 'application/x-ms-wmz',
    msi: 'application/x-msi',
    pac: 'application/x-ns-proxy-autoconfig',
    nwc: 'application/x-nwc',
    o: 'application/x-object',
    oza: 'application/x-oz-application',
    p12: 'application/x-pkcs12',
    p7r: 'application/x-pkcs7-certreqresp',
    crl: 'application/x-pkcs7-crl',
    qtl: 'application/x-quicktimeplayer',
    shar: 'application/x-shar',
    swf: 'application/x-shockwave-flash',
    sit: 'application/x-stuffit',
    sv4cpio: 'application/x-sv4cpio',
    sv4crc: 'application/x-sv4crc',
    tar: 'application/x-tar',
    texinfo: 'application/x-texinfo',
    texi: 'application/x-texinfo',
    t: 'application/x-troff',
    roff: 'application/x-troff',
    man: 'application/x-troff-man',
    ustar: 'application/x-ustar',
    src: 'application/x-wais-source',
    wz: 'application/x-wingz',
    webarchive: 'application/x-webarchive',
    webarchivexml: 'application/x-webarchive-xml',
    crt: 'application/x-x509-ca-cert',
    xcf: 'application/x-xcf',
    fig: 'application/x-xfig',
    xhtml: 'application/xhtml+xml',
    '3gpp': 'audio/3gpp',
    amr: 'audio/amr',
    snd: 'audio/basic',
    mid: 'audio/midi',
    midi: 'audio/midi',
    kar: 'audio/midi',
    xmf: 'audio/midi',
    mxmf: 'audio/mobile-xmf',
    mpga: 'audio/mpeg',
    mpega: 'audio/mpeg',
    mp2: 'audio/mpeg',
    mp3: 'audio/mpeg',
    m4a: 'audio/mpeg',
    m3u: 'audio/mpegurl',
    sid: 'audio/prs.sid',
    aif: 'audio/x-aiff',
    aiff: 'audio/x-aiff',
    aifc: 'audio/x-aiff',
    gsm: 'audio/x-gsm',
    wma: 'audio/x-ms-wma',
    wax: 'audio/x-ms-wax',
    ra: 'audio/x-pn-realaudio',
    rm: 'audio/x-pn-realaudio',
    ram: 'audio/x-pn-realaudio',
    pls: 'audio/x-scpls',
    sd2: 'audio/x-sd2',
    wav: 'audio/x-wav',
    bmp: 'image/bmp',
    qcp: 'audio/x-qcp',
    gif: 'image/gif',
    cur: 'image/ico',
    ico: 'image/ico',
    ief: 'image/ief',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    jpe: 'image/jpeg',
    pcx: 'image/pcx',
    png: 'image/png',
    svg: 'image/svg+xml',
    svgz: 'image/svg+xml',
    tiff: 'image/tiff',
    tif: 'image/tiff',
    djvu: 'image/vnd.djvu',
    djv: 'image/vnd.djvu',
    wbmp: 'image/vnd.wap.wbmp',
    ras: 'image/x-cmu-raster',
    cdr: 'image/x-coreldraw',
    pat: 'image/x-coreldrawpattern',
    cdt: 'image/x-coreldrawtemplate',
    art: 'image/x-jg',
    jng: 'image/x-jng',
    psd: 'image/x-photoshop',
    pnm: 'image/x-portable-anymap',
    pbm: 'image/x-portable-bitmap',
    pgm: 'image/x-portable-graymap',
    ppm: 'image/x-portable-pixmap',
    rgb: 'image/x-rgb',
    xbm: 'image/x-xbitmap',
    xpm: 'image/x-xpixmap',
    xwd: 'image/x-xwindowdump',
    igs: 'model/iges',
    iges: 'model/iges',
    msh: 'model/mesh',
    mesh: 'model/mesh',
    silo: 'model/mesh',
    ics: 'text/calendar',
    icz: 'text/calendar',
    csv: 'text/comma-separated-values',
    css: 'text/css',
    htm: 'text/html',
    html: 'text/html',
    323: 'text/h323',
    uls: 'text/iuls',
    mml: 'text/mathml',
    txt: 'text/plain',
    asc: 'text/plain',
    text: 'text/plain',
    diff: 'text/plain',
    po: 'text/plain',
    rtx: 'text/richtext',
    rtf: 'text/rtf',
    ts: 'text/texmacs',
    phps: 'text/text',
    tsv: 'text/tab-separated-values',
    xml: 'text/xml',
    ifc: 'text/plain',
    bib: 'text/x-bibtex',
    boo: 'text/x-boo',
    'h++': 'text/x-c++hdr',
    hpp: 'text/x-c++hdr',
    hxx: 'text/x-c++hdr',
    hh: 'text/x-c++hdr',
    'c++': 'text/x-c++src',
    cpp: 'text/x-c++src',
    cxx: 'text/x-c++src',
    h: 'text/x-chdr',
    htc: 'text/x-component',
    csh: 'text/x-csh',
    c: 'text/x-csrc',
    d: 'text/x-dsrc',
    hs: 'text/x-haskell',
    java: 'text/x-java',
    lhs: 'text/x-literate-haskell',
    moc: 'text/x-moc',
    p: 'text/x-pascal',
    pas: 'text/x-pascal',
    gcd: 'text/x-pcs-gcd',
    etx: 'text/x-setext',
    tcl: 'text/x-tcl',
    tex: 'text/x-tex',
    ltx: 'text/x-tex',
    sty: 'text/x-tex',
    cls: 'text/x-tex',
    vcs: 'text/x-vcalendar',
    vcf: 'text/x-vcard',
    '3gp': 'video/3gpp',
    '3g2': 'video/3gpp',
    dl: 'video/dl',
    dif: 'video/dv',
    dv: 'video/dv',
    fli: 'video/fli',
    m4v: 'video/m4v',
    mpeg: 'video/mpeg',
    mpg: 'video/mpeg',
    mpe: 'video/mpeg',
    mp4: 'video/mp4',
    vob: 'video/mpeg',
    qt: 'video/quicktime',
    mov: 'video/quicktime',
    mxu: 'video/vnd.mpegurl',
    webm: 'video/webm',
    lsf: 'video/x-la-asf',
    lsx: 'video/x-la-asf',
    mng: 'video/x-mng',
    asf: 'video/x-ms-asf',
    asx: 'video/x-ms-asf',
    wm: 'video/x-ms-wm',
    wmv: 'video/x-ms-wmv',
    wmx: 'video/x-ms-wmx',
    wvx: 'video/x-ms-wvx',
    avi: 'video/x-msvideo',
    movie: 'video/x-sgi-movie',
    ice: 'x-conference/x-cooltalk',
    sisx: 'x-epoc/x-sisx-app'
}
